import { getCurrencyCode, queryKey, useDateTransformerFactory } from '@segunosoftware/equinox';
import type { CurrencyCode } from '@shopify/react-i18n';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo } from 'react';
import { ACCOUNT, CACHED_ACCOUNT } from './query-keys';
import type { Get, Post } from './types';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

export const PROMO_CODES = {
	HOLIDAYSP: {
		amount: 0.5,
		duration: 3
	},
	HOLIDAYS: {
		amount: 0.25,
		duration: 3
	},
	MATESRATES: {
		amount: 0.2,
		duration: undefined
	}
	// BULK50: {
	// 	amount: 0.5,
	// 	duration: 1
	// },
	// BULK2025: {
	// 	amount: 0.5,
	// 	duration: 3
	// }
};

export type PromoCode = keyof typeof PROMO_CODES;

export const ALL_INDUSTRIES = [
	'beauty',
	'clothing',
	'electronics',
	'furniture',
	'handcrafts',
	'jewelry',
	'painting',
	'photography',
	'restaurants',
	'groceries',
	'other_food_drink',
	'sports',
	'toys',
	'services',
	'virtual_services',
	'other',
	'do_not_know'
] as const;
export type Industry = (typeof ALL_INDUSTRIES)[number];

export type AccountBillingStatus = 'unbilled' | 'active' | 'frozen' | 'cancelled';
export type ReviewStatus = 'ignored' | 'reviewed' | 'denied' | 'deferred';
export type ShopPlan =
	| 'basic'
	| 'professional'
	| 'unlimited'
	| 'shopify_plus'
	| 'plus_partner_sandbox'
	| 'partner_test'
	| 'affiliate'
	| 'frozen'
	| 'cancelled'
	| 'paused'
	| 'staff'
	| 'staff_business'
	| 'trial'
	| 'fraudulent'
	| 'npo_full'
	| 'npo_lite'
	| 'business'
	| 'sales_training'
	| 'comped'
	| 'dormant'
	| 'custom'
	| 'enterprise'
	| 'starter'
	| 'shopify_alumni'
	| 'open_learning'
	| 'grandfather';

export const ALL_DISTRIBUTION_CHANNELS = ['email', 'sms', 'social', 'direct_mail', 'customer_service', 'retail'] as const;
export type DistributionChannel = (typeof ALL_DISTRIBUTION_CHANNELS)[number];

export type AccountSurvey = {
	contactName: string;
	contactEmail: string;
	industry: Industry;
	distributionChannels: DistributionChannel[];
	multipleShops: boolean;
};

export type Account = {
	id: number;
	userHash: string;
	supportUserHash: string;
	name?: string;
	publicId?: string;
	ownerName?: string;
	ownerEmail?: string;
	contactName?: string;
	contactEmail?: string;
	phone?: string;
	shop: string;
	primaryLocale: string;
	currency: CurrencyCode;
	timezone: string;
	countryCode: string;
	domain?: string;
	platformPlan?: ShopPlan;
	scopes: string[];
	gaClientId?: string;
	billingStatus?: AccountBillingStatus;
	basePrice: number;
	promoCode?: PromoCode;
	monthlyImportedCodes: number;
	totalImportedCodes: number;
	discountSetsAllowed: number;
	discountSetsCreated: number;
	freePlan: boolean;
	reviewRating: number;
	reviewStatus: ReviewStatus;
	accountSurvey?: AccountSurvey;
	createdAt: Date;
	updatedAt: Date;
};

function tranformCurrencyCode(account: Account | undefined) {
	if (!account) {
		return account;
	}
	const { currency } = account;
	return { ...account, currency: getCurrencyCode(currency) };
}

function useSetAccount() {
	const queryClient = useQueryClient();
	return useCallback(
		(account: Account) => {
			if (account) {
				queryClient.setQueryData(queryKey(ACCOUNT), account);
				queryClient.invalidateQueries({ queryKey: queryKey(CACHED_ACCOUNT) });
			}
		},
		[queryClient]
	);
}

// only use this to do the initial account loading
export function useAccountLoader(enabled = true) {
	const client = useQueryClient();
	const { get } = useAuthenticatedFetch() as Get<Account>;
	const { data, isFetching: isLoading } = useQuery({
		queryKey: queryKey(ACCOUNT),
		queryFn: () => get('/auth/whoami'),
		select: useDateTransformerFactory(),
		enabled
	});

	const account = useMemo(() => tranformCurrencyCode(data), [data]);

	useEffect(() => {
		const cachedAccount = client.getQueryData<Account>(queryKey(CACHED_ACCOUNT));
		if (cachedAccount === null && !isLoading && account) {
			client.invalidateQueries({ queryKey: queryKey(CACHED_ACCOUNT) });
		}
	}, [account, isLoading, client]);

	return { account, isLoadingAccount: isLoading };
}

export function useAccount() {
	const { account } = useAccountLoader(false); // enabled is false here because someone else must load the account for us

	if (!account) {
		throw new Error('account not loaded'); // the account must be loaded before anyone uses this hook! (ProtectedRoute does this)
	}

	return account;
}

export function useCachedAccount() {
	const client = useQueryClient();
	const { data: account } = useQuery({
		queryKey: queryKey(CACHED_ACCOUNT),
		queryFn: () => Promise.resolve(client.getQueryData<Account>(queryKey(ACCOUNT)) || null)
	});
	return account;
}

export function useSetClientId() {
	type SetClientIdRequest = { clientId: string };
	const { post } = useAuthenticatedFetch() as Post<SetClientIdRequest, Account>;
	const setAccount = useSetAccount();
	const setAccountOption = { onSuccess: setAccount };

	const { mutate: setClientId, isPending: isLoading } = useMutation({
		mutationFn: (clientId: string) => post('/account/set-client-id', { clientId }),
		...setAccountOption
	});

	return {
		setClientId,
		isLoading
	};
}

export function useReviewRating() {
	const { post } = useAuthenticatedFetch() as Post<any, Account>;
	const setAccount = useSetAccount();
	const setAccountOption = { onSuccess: setAccount };

	const { mutate: setReviewRating, isPending: isSetReviewRatingLoading } = useMutation({
		mutationFn: (reviewRating: number) => post('/account/review-rating', { rating: reviewRating }),
		...setAccountOption
	});

	const { mutate: setReviewStatus, isPending: isSetReviewStatusLoading } = useMutation({
		mutationFn: (reviewStatus: ReviewStatus) => post('/account/review-status', { reviewStatus }),
		...setAccountOption
	});

	return {
		setReviewRating: (reviewRating: number) => setReviewRating(reviewRating),
		isSetReviewRatingLoading,
		setReviewStatus: (reviewStatus: ReviewStatus) => setReviewStatus(reviewStatus),
		isSetReviewStatusLoading
	};
}

export function useSetPromoCode() {
	const { post } = useAuthenticatedFetch() as Post<any, Account>;
	const setAccount = useSetAccount();

	const {
		mutate: setPromoCode,
		isPending: isLoading,
		isSuccess,
		isError
	} = useMutation({
		mutationFn: (promoCode: PromoCode) => post('/account/set-promo-code', { promoCode }),
		onSuccess: setAccount
	});

	return {
		setPromoCode,
		isLoading,
		isSuccess,
		isError
	};
}

export function useAccountSurvey() {
	const { post } = useAuthenticatedFetch() as Post<AccountSurvey, Account>;
	const setAccount = useSetAccount();

	const { mutate: submitAccountSurvey, isPending: isLoading } = useMutation({
		mutationFn: (survey: AccountSurvey) => post('/account/survey', survey),
		onSuccess: setAccount
	});

	return {
		submitAccountSurvey,
		isLoading
	};
}
